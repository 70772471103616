.react-datepicker__day--selected {
  background-color: #49a1f4;
  text-align: center;
  color: white;
}

.react-datepicker__day--keyboard-selected {
  background-color: #8cc3f6;
}

.react-datepicker__day-name {
  font-weight: 500;
  color: #333;
}

.react-datepicker__day:hover {
  border-radius: 50%;
  background-color: #8cc3f6;
  color: white;
}

.react-datepicker__day {
  border-radius: 50%;
}

.react-datepicker__header {
  background-color: white;
  border-bottom: 1px solid #e0e0e0;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.react-datepicker__input-container {
  width: fit-content;
  display: flex;
  align-items: center;
}

.react-datepicker__day--outside-month {
  color: #667085;
}

.react-datepicker__calendar-icon {
  fill: #00000099;
}

.react-datepicker__input-container input {
  margin: 0px;
  padding-left: 35px;
  border: none;
  border-radius: 2px;
  box-shadow: 0 0 0 1px #9e9eaf;
  outline: none;
  background-color: #f8f8f8;
}

.react-datepicker__input-container input:focus {
  margin: 0px;
  border: none;
  box-shadow: 0 0 0 2px #49a1f4;
  border-radius: 2px;
  border-width: 2px;
  outline: none;
  background-color: #f8f8f8;
}

.react-datepicker-wrapper {
  width: fit-content !important;
}

.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::after {
  border-bottom-color: white;
  color: white;
}

.react-datepicker__current-month {
  font-weight: 500;
  color: #333;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  top: 0;
  margin-top: 0.5rem;
  opacity: 0.7;
  transition: opacity 0.3s;
}

.react-datepicker__navigation--previous:hover,
.react-datepicker__navigation--next:hover {
  opacity: 1;
}

.react-datepicker {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

@media only screen and (max-width: 490px) {
  .react-datepicker {
    right: 15px;
    overflow: hidden;
  }
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--disabled {
  display: none;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #49a1f4;
}

.react-datepicker-time__header {
  font-weight: 500;
  color: #333;
}

.react-datepicker__day--disabled {
  color: #b0b0b0;
}

.react-datepicker__day--disabled:hover {
  background-color: transparent;
  color: #b0b0b0;
}

.react-datepicker__day--outside-month.react-datepicker__day--disabled {
  color: #b0b0b0;
}

.react-datepicker__day--outside-month--disabled:hover {
  background-color: transparent;
}
